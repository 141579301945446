import { useEffect } from 'react';

function Privacy() {

    useEffect(() => {
        document.title = 'NakedShortReport | Privacy Policy';
        document.getElementsByTagName('meta')['keywords'].content = ' short selling, Nasdaq Short Data, Nasdaq Short Report, Nasdaq Short Squeeze ,NYSE Short Position, Market Makers Shorting NASDAQ ,NYSE Naked Short Selling, NYSE Short Interest, SHORT SELLING SHARES';
        document.getElementsByTagName('meta')['description'].content = 'Privacy Policy';
    }, []);

    return (
        <>
            <div className="container">
	            <div className="midcontent">
		            <div className="leftc leftcp">
			            <div className="namec">
				            <div className="namet">Privacy Policy</div>
                            <div className="divider"></div>
                            <div className="namet">We keep your personal data private and confidential.</div>
                            <div className="divider"></div>
                            <div className="namet">NakedShortReport.com understands the importance of privacy and security to our subscribers.</div>
                            <div className="divider"></div>
                            <div className="para">NakedShortReport.com is committed to protecting the privacy of all our visitors and subscribers. Your right to privacy is very important to us. When you choose to provide us with information about yourself, we recognize that you trust us to act in a responsible manner.</div>
                            <div className="namet">Usage of Information</div>
                            <div className="divider"></div>
                            <div className="para">Any information you provide to us is maintained and accessible only by our Websites. NakedShortReport.com uses this information to send you information and updates. We do not disclose any information about any individual user to entities outside of those partners except to comply with applicable law or valid legal process.</div>
                            <div className="namet">Sharing of Information</div>
                            <div className="divider"></div>
                            <div className="para">NakedShortReport.com does not sell, rent, lease or share any user information that it has collected over this website.</div>
                            <div className="namet">Opt-Out from mail list</div>
                            <div className="divider"></div>
                            <div className="para">At any time you may request to be removed from our mailing list by clicking the unsubscribe link provided in each newsletter.
                            <br />If you have any questions or concerns, we invite you to <a href="contact">contact us</a></div>
            			</div>
			            <br /><br /><br /><br /><br />
		            </div>
	            </div>
            </div>
        </>
    );
}

export default Privacy;