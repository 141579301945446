import { useEffect } from 'react';

function About() {

    useEffect(() => {
        document.title = 'NakedShortReport | About Us';
        document.getElementsByTagName('meta')['keywords'].content = ' short selling, Nasdaq Short Data, Nasdaq Short Report, Nasdaq Short Squeeze ,NYSE Short Position, Market Makers Shorting NASDAQ ,NYSE Naked Short Selling, NYSE Short Interest, SHORT SELLING SHARES';
        document.getElementsByTagName('meta')['description'].content = 'About us';
    }, []);
    
    return (
        <>
            <div className="container">
                <div className="midcontent">
                    <div className="leftc leftcp">
                        <div className="namec">
                            <div className="namet">About Us</div>
                            <div className="divider"></div>
                            <div className="para">NakedShortReport.com was put together for one reason. To track the naked short selling on NASDAQ and NYSE stocks that is causing destruction to our capital markets. We display naked short trade data for NASDAQ and NYSE equities in a coherent fashion and report the daily naked short positions on these stocks as reported by FINRA under the regsho reporting rules. At NakedShortReport.com we make understanding naked short interests in NASDAQ and NYSE stocks easy to understand by displaying the short trade totals in a stock chart type display. We also display historical data on naked short trades. Our goal is to help educate user base,  save our users time in displaying all the data in one easy place, and most importantly offering this service for free!</div>
                            <div className="para">We hope to help investors with understanding what is really happening with your NASDAQ and NYSE investments.</div>
                            <div className="para">Believe it or not, most CEO's are not even aware that they are a victim of manipulative short selling!</div>
                            <div className="para">We always look forward to comments and suggestions. If there are any features you would like to see, or anything we can help with making your experience a better one, please don't hesitate to contact us.</div>
                            <div className="para">NakedShortReport.com "Free Daily Naked Short Position Reports on all your NASDAQ and NYSE Investments"</div>
                            <div className="para">For those of you looking for short data on OTC companies, please visit our sister website at <a href="http://otcshortreport.com" rel="noopener noreferrer" target="_blank">www.otcshortreport.com</a></div>
                        </div>
                        <br /><br /><br /><br /><br />
                    </div>
                </div>
            </div>
        </>
    );
}


export default About;