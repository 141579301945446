import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

function Header() {
    
    const [mostShortedNSQ, setMostShortedNSQ] = useState([]);
    const [mostShortedNYX, setMostShortedNYX] = useState([]);
    
    const searchRef = useRef(null);

    useEffect(() => {
        
        /**Check if mostShorted Local storage array is set**/
        if(localStorage.getItem("mostShortedNSQ") === null) {
            localStorage.setItem("mostShortedNSQ", '');
        }
        if(localStorage.getItem("mostShortedNYX") === null) {
            localStorage.setItem("mostShortedNYX", '');
        }

        /** Check if Cache Time is set **/
        if(localStorage.getItem("mostShortedStocksTime") === null) {
            localStorage.setItem("mostShortedStocksTime", Math.round(new Date().getTime() / 1000));
        }

        async function loadMostShorted() {
            try {
                const mSResponse = await fetch('/ajax.php?action=mostshorted');
                const mJsonObj = await mSResponse.json();
                localStorage.setItem("mostShortedNSQ", mJsonObj.NSQ);
                localStorage.setItem("mostShortedNYX", mJsonObj.NYX);
                localStorage.setItem("mostShortedStocksTime", Math.round(new Date().getTime() / 1000));
                setMostShortedNSQ(mJsonObj.NSQ);
                setMostShortedNYX(mJsonObj.NYX);
            }
            catch(e) {
                console.log(e);
            }
        }

        /** Set New Cache Time if difference is greater thatn 10 mins**/
        if(Math.round(new Date().getTime() / 1000) - localStorage.getItem("mostShortedStocksTime") >= 600) {
            loadMostShorted();
        }
        else if(
            localStorage.getItem("mostShortedNSQ").length > 0 && 
            localStorage.getItem("mostShortedNYX").length > 0
        ){
            setMostShortedNSQ(localStorage.getItem("mostShortedNSQ").split(','));
            setMostShortedNYX(localStorage.getItem("mostShortedNYX").split(','));
        }
        else {
            loadMostShorted();
        }

    }, []);
        
    function handleSubmit(e) {
        e.preventDefault();
        let searchVal = searchRef.current.value.toUpperCase().replace(/\s/g, "");
        searchVal = searchVal.substr(0, 10);
        if(searchVal.length > 0) {
            window.location.href = '/company/'+searchVal;
        }
    }

    return (
        <>
            <div className="topmenuc">
                <div className="container">
                    <div className="menuitemc active" onClick={(e) => document.location='/'}>
                        <img src="/img/nsr_logo.png" alt="NAKEDSHORTREPORT.COM"/>
                        <div>NAKEDSHORTREPORT.COM</div>
                    </div>
                    <div className="menuitemc" onClick={(e) => document.location='https://otcshortreport.com'}>
                        <img src="/img/otclogo.png" alt="OTCSHORTREPORT.COM"/>
                        <div>OTCSHORTREPORT.COM</div>
                    </div>
                    <div className="menuitemc" onClick={(e) => document.location='https://shortdata.ca/'}>
                        <img src="/img/shortdata_logo.png" alt="SHORTDATA.CA"/>
                        <div>CANADIAN SHORT DATA</div>
                    </div>
                </div>	
            </div>
            <div className="menuborder"></div>
            <div className="logoc">
                <div className="container">
                    <div className="headerh">
                        <div className="logo" onClick={(e) => document.location='/'}>
                            <span className="logo1">NAKED</span>
                            <span className="logo2">ShortReport</span>
                        </div>
                        <div className="searchc">
                            <form onSubmit={handleSubmit}>
                            <input type="text" placeholder="stock symbol" ref={searchRef} id="search" maxLength="10" autoComplete="off" />
                            <div id="searchsubmit" onClick={handleSubmit}></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="topmenub">
                <div className="container">
                    <div className="th"><Link to="/what-is-naked-short-selling">What is Naked Short Selling?</Link></div>
                    <div className="thd">|</div>
                    <div className="th"><Link to="/how-to-detect-short-trades">How to Detect Naked Short Trades?</Link></div>
                    <div className="thd">|</div>
                    <div className="th"><Link to="/faq">FAQ's</Link></div>
                </div>		
            </div>
            <div className="tickerc">
                <div className="container">
                    <div className="th">Most Shorted NASDAQ Stocks:</div>
                    <div className="mostshortedc">
                    {
                        mostShortedNSQ.map((mSNSQ, index) => 
                            <div key={index} className={index == 0 ? 'ms msfirst' : 'ms'}>
                                <a href={`/company/${mSNSQ}`}>{mSNSQ}</a>
                            </div>
                        )
                    }    
                    </div>
                </div>		
            </div>
            <div className="tickercsub">
                <div className="container">
                    <div className="th">Most Shorted NYSE Stocks:</div>
                    <div className="mostshortedc">
                    {
                        mostShortedNYX.map((mSNYX, index2) => 
                            <div key={index2} className={index2 == 0 ? 'ms ms2 msfirst' : 'ms ms2'}>
                                <a href={`/company/${mSNYX}`}>{mSNYX}</a>
                            </div>
                        )
                    }    
                    </div>
                </div>		
            </div>
        </>
    );
}

export default Header;