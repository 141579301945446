import { useEffect } from 'react';

function Disclaimer() {
    
    useEffect(() => {
        document.title = 'NakedShortReport | Disclaimer';
        document.getElementsByTagName('meta')['keywords'].content = ' short selling, Nasdaq Short Data, Nasdaq Short Report, Nasdaq Short Squeeze ,NYSE Short Position, Market Makers Shorting NASDAQ ,NYSE Naked Short Selling, NYSE Short Interest, SHORT SELLING SHARES';
        document.getElementsByTagName('meta')['description'].content = 'Disclaimer';
    }, []);

    return (
        <>
            <div className="container">
	            <div className="midcontent">
		            <div className="leftc leftcp">
			            <div className="namec">
				            <div className="namet">Disclaimer</div>
                            <div className="divider"></div>
                            <div className="para">NAKEDSHORTREPORT.com, its affiliates and third party providers are not responsible for any errors or omissions in the information contained on or accessed through this Site. All such information in this Site is provided "as is". NAKEDSHORTREPORT.com, its affiliates and third party providers make no warranties or representations and disclaim all express, implied and statutory warranties of any kind to the user and any third party, including, but not limited to, any warranties of accuracy, timeliness, completeness, merchantability, non-infringement and fitness for any particular purpose.</div>
                            <div className="para">Except for claims that cannot be excluded by the applicable local law, NAKEDSHORTREPORT.com, its affiliates and third party providers shall not have any liability, including but not limited to, tort, contract or any other liability to user or any third party arising as a result of use of or access to this Site.</div>
                            <div className="para">NAKEDSHORTREPORT.com, its affiliates or third party providers shall not be liable to user or any third party for lost profits or lost opportunity, direct, indirect, special, consequential, incidental or punitive damages whatsoever, even if NAKEDSHORTREPORT.com, its affiliates or third party providers have been advised of the possibility of such damages.</div>
                            <div className="para">Some jurisdictions do not allow for the exclusion or limitation of implied warranties or liability for incidental or consequential damages. Therefore, the above limitations may not apply to you, or there may be applicable laws which supersede the above. Any clause of this disclaimer declared invalid by the appropriate authority in each jurisdiction shall be deemed severable and shall not affect the validity or enforceability of the remainder of this disclaimer. The terms of this disclaimer are governed by the laws of the State of New York. NAKEDSHORTREPORT.com reserves the right to amend the document at its discretion at any time without notice.</div>
                            <div className="namet">Advertising & Banners</div>
                            <div className="divider"></div>
                            <div className="para">All material listed within NAKEDSHORTREPORT.com was prepared by NAKEDSHORTREPORT.com based upon information supplied by the companies, promoters, or from other sources believed to be reliable. The information contained on NAKEDSHORTREPORT.com is not guaranteed by NAKEDSHORTREPORT.com to be accurate and should not considered to be all-inclusive.</div>
                            <div className="para">The companies and or promoters discussed on NAKEDSHORTREPORT.com have not approved the statements made herein.</div>
                            <div className="para">Any discussions and pages may contain forward looking statements that involve risks and uncertainties. A company's actual results could differ materially from those described in any forward looking statements or announcements mentioned on NAKEDSHORTREPORT.com.</div>
                            <div className="para">All material is for informational purposes only and should not be construed as an offer or solicitation of an offer to buy or sell securities. NAKEDSHORTREPORT.com is not a licensed broker, broker dealer, market maker, investment banker, investment advisor, analyst, or underwriter. There are risks involved. Always remember that NAKEDSHORTREPORT.com is not an analyst and investing in securities such as the ones listed within are for high risk tolerant individuals only and not the general public.</div>
                            <div className="para">NAKEDSHORTREPORT.com lists promoters and companies for informational purposes only and does not recommend any promoter or company listed within. NAKEDSHORTREPORT.com may receive a fee from promoters listed within for the listing only, not for promotion or advertising of any companies listed within. NAKEDSHORTREPORT.com and its affiliates, officers, directors and employees intend to buy and sell shares in any company mentioned within and may profit in the event those shares rise in value. Whether or not you are an experienced investor you should always consult with a broker before purchasing or selling any securities viewed on http://www.otchshortreport.com and its affiliates, officers, directors and employees are also not attorneys so please consult an attorney for an opinion on anything viewed within.</div>
                            <div className="para">NAKEDSHORTREPORT.com has been compensated up to $1,000 for each banner ad appearing on this site. The content of banner ads is provided by the company featured in the ad.</div>
            			</div>
			            <br /><br /><br /><br /><br />
		            </div>
	            </div>
            </div>
        </>
    );
}

export default Disclaimer;