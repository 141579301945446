import { useEffect } from 'react';

function Faq() {

    useEffect(() => {
        document.title = 'NakedShortReport | FAQ';
        document.getElementsByTagName('meta')['keywords'].content = ' short selling, Nasdaq Short Data, Nasdaq Short Report, Nasdaq Short Squeeze ,NYSE Short Position, Market Makers Shorting NASDAQ ,NYSE Naked Short Selling, NYSE Short Interest, SHORT SELLING SHARES';
        document.getElementsByTagName('meta')['description'].content = 'FAQ';
    }, []);

    return(
        <>
            <div className="container">
	            <div className="midcontent">
		            <div className="leftc leftcp">
			            <div className="namec">
				            <div className="namet">Frequently Asked Questions</div>
                            <div className="divider"></div>
                            <div className="namet">What is Naked Short Selling?</div>
                            <div className="divider"></div>
                            <div className="para">In a "naked" short sale, the seller does not borrow or arrange to borrow the securities in time to make delivery to the buyer within the standard three-day settlement period. 3 As a result, the seller fails to deliver securities to the buyer when delivery is due (known as a "failure to deliver" or "fail").
                            <a href="http://www.sec.gov/spotlight/keyregshoissues.htm">http://www.sec.gov/spotlight/keyregshoissues.htm</a></div>
                            <div className="namet">What is a Threshold Security?</div>
                            <div className="divider"></div>
                            <div className="para">
                            Threshold securities are equity securities that have an aggregate fail to deliver position for:<br />
                            *five consecutive settlement days at a registered clearing agency (e.g., National Securities Clearing Corporation (NSCC))<br />
                            *totaling 10,000 shares or more<br />
                            *equal to at least 0.5% of the issuer's total shares outstanding<br />
                            Threshold securities only include issuers registered or required to file reports with the Commission ("reporting companies"). Therefore, securities of issuers that are not registered or required to file reports with the Commission, which includes the majority of issuers on the Pink Sheets, cannot be threshold securities. This is because the SROs need to look to the total outstanding shares of the issuer in order to calculate whether or not the securities meet the definition of a "threshold security." For non-reporting companies, reliable information on total outstanding shares is difficult to determine.<a href="http://www.sec.gov/spotlight/keyregshoissues.htm">http://www.sec.gov/spotlight/keyregshoissues.htm</a> 
                            </div>
                            <div className="namet">What is the purpose of NakedShortReport.com?</div>
                            <div className="divider"></div>
                            <div className="para">Our short reporting platform was developed to provide all available naked short selling data on NASDAQ and NYSE stocks.</div>
                            <div className="namet">What are NASDAQ & NYSE Stocks?</div>
                            <div className="divider"></div>
                            <div className="para">
                            NASDAQ Stocks are companies that are listed on the NASDAQ Stock Exchange, like Facebook, Apple and Amazon. Visit: http://www.nasdaq.com 
                            NYSE Stocks are companies that are listed on the NYSE Stock Exchange, like Exxon Mobil, Pfizer and General Electric. Visit: <a href="http://www.nyse.com">http://www.nyse.com</a></div>
                            <div className="namet">What Markets Does NakedShortReport.com Provide Data for?</div>
                            <div className="divider"></div>
                            <div className="para">We track NASDAQ and NYSE on this website. Our sister platform tracks OTC stocks that have naked short positions. Visit <a href="http://www.otcshortreport.com">http://www.otcshortreport.com</a> for more information on OTC traded stocks.</div>
                            <div className="namet">Why is this service useful?</div>
                            <div className="divider"></div>
                            <div className="para">Understanding what percentage of any particular stocks daily volume that is naked short is extremely important in understanding the level of manipulation occurring on that particular security. Market Makers and big traders are shorting companies in increased frequency. Short Sellers do not care whether or not a business plan is feasible, but rather how much volume they can capitalize on, by shorting "selling shares" to new investors that may otherwise believe in the company or business plan. Understanding the amount of negative pressure on a stock can help gauge what the market sentiment of a particular stock is, and or what the big money is doing.</div>
                            <div className="namet">Do I have to pay for this Service?</div>
                            <div className="divider"></div>
                            <div className="para">No. NakedShortReport.com is free of charge.</div>
                            <div className="namet">How does your service make money?</div>
                            <div className="divider"></div>
                            <div className="para">We charge for advertising space on our platform.</div>
                            <div className="namet">Who is this service designed for?</div>
                            <div className="divider"></div>
                            <div className="para">Our service is designed for anybody interested in understanding important elements about a company and its trading patterns. Many CEO's of public companies use our service to track whats happening with their stock on a daily basis. Furthermore, many investors, both retail and institutional investors use our service to track what is happening with their investments and as a due diligence tool any any potential investments.</div>
                            <div className="namet">I have signed up and I am not receiving your alerts, why?</div>
                            <div className="divider"></div>
                            <div className="para">Please check your spam folder and be sure to ad us to your Safe Sender list. Follow these instructions in order to ad us to your safe sender list.</div>
                            <div className="namet">How often is naked short data updated?</div>
                            <div className="divider"></div>
                            <div className="para">We update our daily short data everyday at 6pm Eastern Standard Time. In some very rare cases, short data is updated the next day.</div>
                            <div className="namet">How many companies do you track short data for?</div>
                            <div className="divider"></div>
                            <div className="para">We track over 5,900 companies trading on NASDAQ and NYSE stock exchanges. </div>
                            <div className="namet">Can I advertise on NakedShortReport.com?</div>
                            <div className="divider"></div>
                            <div className="para">Yes we offer banner space on our website. Please contact us to discuss your needs.</div>
			            </div>
			            <br /><br /><br /><br /><br />
		            </div>
	            </div>
            </div>
        </>
    );
}

export default Faq;