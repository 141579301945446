import { Link } from "react-router-dom";

function Footer() {
    
    return (
        <>
            <div className="footerc">
                <div className="container">
                    <div className="footermenuc">
                        <div className="fmi fmifirst"><Link to="/about">About NAKEDShortReport.com</Link></div>
                        <div className="fmi"><Link to="/privacy-policy">Privacy Policy</Link></div>
                        <div className="fmi"><Link to="/disclaimer">Disclaimer</Link></div>
                    </div>
                    <div className="disclaimer">
                        Most stock quote data provided by Yahoo. Market indices are delayed by fifteen minutes. All times are ET. Disclaimer. Harris Market Consulting.: &copy; 2021 Harris Market Consulting. All Rights Reserved. FINRA:REGSHO data provided by FINRA. All rights reserved. All content of the Dow Jones branded indices &copy; S&P Dow Jones Indices LLC 2015 and/or its affiliates. All short data algorithms are property of Harris Market Consulting.</div>
                    <div className="disclaimer">This website provides content that is free of charge and for informational purposes only. Do not use this website to base any investment decisions. Please read our disclaimers and disclosures.</div>
                    <div className="disclaimer">
                        2021 NakedShortReport.com An Harris Market Consulting. Company. All Rights Reserved. Terms under which this service is provided to you. Please read our disclosure and privacy policies.</div>
                </div>
            </div>
        </>
    );
}

export default Footer;