import { useEffect } from 'react';

function Hds() {

    useEffect(() => {
        document.title = 'NakedShortReport | How to detect Short trades';
        document.getElementsByTagName('meta')['keywords'].content = ' short selling, Nasdaq Short Data, Nasdaq Short Report, Nasdaq Short Squeeze ,NYSE Short Position, Market Makers Shorting NASDAQ ,NYSE Naked Short Selling, NYSE Short Interest, SHORT SELLING SHARES';
        document.getElementsByTagName('meta')['description'].content = 'How to detect Short trades';
    }, []);

    return (
        <>
            <div className="container">
                <div className="midcontent">
                    <div className="leftc leftcp">
                        <div className="namec">
                            <div className="namet">How to Detect Naked Short Trades</div>
                            <div className="divider"></div>
                            <div className="para">There are many tell tale signs that a company is being naked short sold. Do you think your company or investment is under attack? Here is what you should keep your eye out on:</div>
                            <div className="para">1. Has the stock been on a continual downtrend over the past several months with no material events or known reasons for why it has depreciated?</div>
                            <div className="para">2. Does the stock see downwards pressure anytime the company outs out a press release?</div>
                            <div className="para">3. Do you notice any unscrupulous posts or new handles popping in bashing the company, it’s story or management? It is common for short sellers – both regular and naked short sellers to hire bashers and deploy them on the stock message boards and social media.</div>
                            <div className="para">4. Do you ever notice weird uneven trade lots? Example, someone traded 1,172 shares or an odd lot, but frequently? This is sometimes a way that market makers and short sellers communicate with each other in the marketplace without the evidence of a text message, email or recorded phone call.</div>
                            <div className="para">5. Are you a company that has gotten phone calls from investors who all of a sudden seem curious in investing in your company? Be wary. We have seen this ploy come out of Germany many times in the past. Traders go short, knock the stock down in the process and come calling for a financing to cover their position. Another illegal tactic but it happens!</div>
                            <div className="para">6. If you are a NASDAQ or NYSE company, rule of thumb is If you see more than 20% of your overall volume initiated short on a daily basis as reported by REGSHO and displayed on our website as per REGSHO guidelines and delivered by FINRA you may be under attack. If you are looking to track naked short selling on OTC companies please visit <a href="http://otcshortreport.com" target="_blank" rel="noopener noreferrer">www.otcshortreport.com</a></div>
                            <div className="namet">Naked Short Selling and The Destruction it Costs</div>
                            <div className="namet nametcb">Death to the Company</div>
                            <div className="para">Naked short selling kills the value of companies and peoples investments by artificially pushing a company’s stock price down. For smaller companies looking to raise working capital, this causes them to have to raise funds at much lower prices which substantially increases the outstanding share count. This is called dilution.</div>
                        </div>
                        <br /><br /><br /><br /><br />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Hds;