import { useEffect } from 'react';

function Wns() {

    useEffect(() => {
        document.title = 'NakedShortReport | What is Naked Short Selling?';
        document.getElementsByTagName('meta')['keywords'].content = ' short selling, Nasdaq Short Data, Nasdaq Short Report, Nasdaq Short Squeeze ,NYSE Short Position, Market Makers Shorting NASDAQ ,NYSE Naked Short Selling, NYSE Short Interest, SHORT SELLING SHARES';
        document.getElementsByTagName('meta')['description'].content = 'What is Naked Short Selling?';
    }, []);

    return(
        <>
            <div className="container">
                <div className="midcontent">
                    <div className="leftc leftcp">
                        <div className="namec">
                            <div className="namet">What is Naked Short Selling?</div>
                            <div className="divider"></div>
                            <div className="para">Before we get into Naked Short Selling let’s understand the basic premises around short selling.</div>
                            <div className="para">Short selling is the sale of a security that is not owned by the seller.</div>
                            <div className="para">The motivation for short selling is an investor's belief that a stock's price will decline, enabling the short seller to buy the stock back in the future at a lower price and make a profit.</div>
                            <div className="para">Normally, when one short sells a stock, their broker will lend them the shares to sell. The loaned stock will come from the broker's own inventory, from another one of the firm's customers, or from another brokerage firm. The shares are sold and the proceeds are credited to the short seller's account.</div>
                            <div className="para">As payment for borrowing the shares, the short seller is charged a fee, quoted as an annualized percentage of the value of the loaned securities - i.e. a borrower of a stock with a 5% stock borrow rate will be charged $5 per year for every $100 of stock borrowed. Stock borrow rates change daily based in large part on the supply and demand to borrow that particular stock.</div>
                            <div className="para">If the number of shares available to borrow is in short supply and/or great demand (which is often the case in highly shorted stocks), finding shares to borrow can be difficult and expensive.</div>
                            <div className="para">A frequently asked question and outlined in our FAQ’s but let’s look at naked short selling from various perspectives.</div>
                            <div className="namet">How does naked short selling effect the stock market?</div>
                            <div className="para">When a seller "naked short sells a stock" they do not own the shares they are selling and therefore are selling artificial shares. This is like counterfeiting a stock. This process creates an obvious unfair advantage to the seller and an imbalance in the market as the sell side is now increased with more shares – many of which are counterfeit. There is a time limit on how long the seller can sell these shares and be naked on the trade and the time limit is 3 days. This is where the RegSho rules come in and the data we track. If the sellers broker-dealer has not located a borrow to cover this short trade within 3 days they will need to purchase back the shares they have sold on the open market. This process is referred to as a "Buy In".</div>
                            <div className="namet">"When it comes to illicit short selling, the shorts win over 90% of the time"</div>
                            <div className="namet">Naked Short – A license to steal?</div>
                            <div className="para">Naked short selling is yet another creation of the securities industry and is in essence nothing more than a license to create counterfeit shares.  When you are inflating the amount of stock that is outstanding in a company, this is considered counterfeiting. The rules justify the practice by saying it helps create smooth, efficient and orderly markets. Same stuff we have heard countless times around high-frequency trading, but in reality we believe this practice leads to shady characters creating unlimited supplies of counterfeit stocks which in turn results in your investment continuing to decline and you wondering why?</div>
                            <div className="para">I am sure you here because you are a shareholder in a company that just continues to go down, and you have no idea why. Nothing material has happened but the trading doesn’t make any sense. We hear it all the times. Most CEO’s don’t even understand, and are baffled. The worst part is, good luck getting anyone to listen! There is a major epidemic going on right now with naked short selling right now.</div>
                            <div className="para">It's funny when we hear CEO's say , I will just buy all the shares up and own the whole O/S and they wont be able to short me anymore. Really?</div>
                            <div className="para">Read about: <a href="http://www.thefaulkingtruth.com/Articles/Investing101/1032.html" target="_blank" rel="noopener noreferrer">Global Links Corporation</a> and see what happened when Robert Simpson purchased 100% of Global Link’s 1,158,064 shares. Then you will truly understand how the system is rigged.  Back to counterfeiting…</div>
                        </div>
                        <br /><br /><br /><br /><br />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Wns;